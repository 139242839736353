export const genresForDisplayWithoutMusic: string[] = [
  'Big Band',
  'Bluegrass',
  'Christian Rock',
  'Comedy',
  'Disco',
  'Dubstep',
  'EDM',
  'Hip Hop',
  'Karaoke',
  'Metal',
  'Reggae',
  'Reggaeton',
  'Singer Songwriter',
  'Spoken Word',
  'Techno',
  'Vocal',
];

export default function genreForDisplay(genre: string): string {
  if (genresForDisplayWithoutMusic.some((arrayGenre) => arrayGenre === genre)) {
    return genre;
  }
  return `${genre} music`;
}
