









import { Component, Prop, Vue } from 'vue-property-decorator';
import ProgressBar from 'progressbar.js';

@Component({
  mixins: [],
  components: {},
})
export default class ProgressBarComponent extends Vue {
  @Prop({ default: 'progress-bar-component' })
  elementId: string;

  @Prop({ default: 'progress' })
  classNames: string;

  @Prop({ default: '#4CD964' })
  color: string;

  @Prop({ default: '' })
  trailColor: string;

  @Prop({ default: 5 })
  strokeWidth: number;

  @Prop({ default: 150 })
  duration: number;

  @Prop({ default: 0 })
  pctComplete: number;

  progressBar: any;

  mounted() {
    this.progressBar = new ProgressBar.Circle(`#${this.elementId}`, {
      color: this.color,
      duration: this.duration,
      easing: 'linear',
      strokeWidth: this.strokeWidth,
      trailColor: this.trailColor,
    });
    this.progressBar.set(0);
    if (this.pctComplete > 0) {
      this.progressBar.animate(this.pctComplete / 100, { duration: this.duration });
    }
  }

  updated() {
    if (this.progressBar) {
      this.progressBar.animate(this.pctComplete / 100, { duration: this.duration });
    }
  }

  beforeDestroy() {
    if (this.progressBar) {
      this.progressBar.destroy();
    }
  }
}
