




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorModal from '@/shared/components/modals/ErrorModal.vue';
import ModalButton from '@/shared/components/modals/ModalButton.vue';
import { FileExceedsMaximumAllowedSizeError } from '@/shared/store/upload';
import { maxImageFileSizeInMb } from '@/shared/lib/defaultFileConstraints';
import { UploadError, InvalidImageDimensionsError } from '@/shared/store/imageLibrary';

@Component({
  components: {
    ModalButton,
    ErrorModal,
  },
})
export default class ImageErrorModal extends Vue {
  $refs!: {
    modal: ErrorModal,
  };

  @Prop({ default: '' }) title: string;

  @Prop({ required: true }) callToAction: string;

  @Prop({ default: () => [] }) callToActionIcon: string[];

  @Prop({ default: () => [] }) errors: UploadError[];

  @Prop({ default: false, type: Boolean }) processing: boolean;

  // autoCloseTimeoutMs: number = 10000;

  get singleInstanceErrors() {
    const errTypes: string[] = [];
    return this.errors.filter((err) => {
      if (errTypes.indexOf(err.error) > -1) {
        return false;
      }
      errTypes.push(err.error);
      return true;
    });
  }

  errorMsg(error: any) {
    if (error.error === FileExceedsMaximumAllowedSizeError) {
      return `Images cannot be larger than ${maxImageFileSizeInMb} MB`;
    }
    if (error.error === InvalidImageDimensionsError) {
      return 'Images must be larger than 600x500 and have an aspect ratio between 1 and 2.5';
    }
    if (error.error.code === 'image_limit_reached') {
      return error.error.message || 'You may have reached the image limit.';
    }
    if (error.error.code === 'invalid_argument' && error.error.meta && error.error.meta.argument === 'file' && error.error.meta.reason === 'invalid format') {
      return 'Images must be either a JPG or a PNG';
    }
    return 'We encountered an issue uploading this image. Please try again.';
  }

  // mounted() {
  //   window.setTimeout(() => {
  //     this.$emit('submit');
  //   }, this.autoCloseTimeoutMs);
  // }
  //
  // updated() {
  //   window.setTimeout(() => {
  //     this.$emit('submit');
  //   }, this.autoCloseTimeoutMs);
  // }

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }

  handleSubmit() {
    this.$emit('submit');
  }
}
