
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageOverlay extends Vue {
  @Prop({ default: false, type: Boolean }) remove: boolean;

  @Prop({ default: '' }) overlay: string;

  get overlayClass() {
    if (this.remove) {
      return 'image-overlay--remove';
    }
    switch (this.overlay) {
      case 'remove':
        return 'image-overlay--remove';
      case 'edit':
        return 'image-overlay--edit';
      case 'add':
        return 'image-overlay--add';
      case 'darken-only':
        return 'image-overlay--darken-only';
      default:
        return 'image-overlay--add';
    }
  }

  get overlayIcon(): string[] {
    if (this.remove) {
      return ['far', 'times'];
    }
    switch (this.overlay) {
      case 'remove':
        return ['far', 'times'];
      case 'edit':
        return ['far', 'pencil'];
      case 'add':
        return ['far', 'plus'];
      case 'darken-only':
        return [];
      default:
        return ['far', 'plus'];
    }
  }
}
