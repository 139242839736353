import store from '@/shared/store';

function getUGC(Key: string): string {
  const draft = store.getters['onboarding/getDraft'];
  if (draft.ugc && draft.ugc.fields[Key]) {
    return draft.ugc.fields[Key] || '';
  }
  return '';
}

function setUGC(Key: string, Value: string): void {
  const payload = {
    Key,
    Value,
  };
  store.dispatch('onboarding/updateUGC', payload);
}

export {
  getUGC,
  setUGC,
};
