








































































































import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import infiniteScroll from 'vue-infinite-scroll';

import { MemberImage, UserImage } from '@/shared/gen/messages.pisa';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import { FilterMapValidation } from '@/shared/store/recommended_ads';
import { getUGC } from '@/shared/lib/ugc_adaptor';

import AddImagesButton from '@/shared/components/builder/AddImagesButton.vue';
import OpenImageLibraryButton from '@/shared/components/builder/OpenImageLibraryButton.vue';
import ProgressBarComponent from '@/shared/components/common/ProgressBarComponent.vue';
import SelectDropdown from '@/shared/components/common/SelectDropdown.vue';
import genreForDisplay from '@/shared/lib/genre-display-mappings';

@Component({
  components: {
    AddImagesButton,
    OpenImageLibraryButton,
    ProgressBarComponent,
    SelectDropdown,
  },
  directives: {
    infiniteScroll,
  },
})
export default class MemberImages extends Vue {
  $refs!: {
    memberImages: HTMLElement,
  }

  progressBarColor: string = '#552EFF';

  @Prop({ default: () => [] }) selectedImageIds: string[];

  @Prop({ default: -1 }) limit: number;

  @Prop({ default: false, type: Boolean }) showSelectedCount: boolean;

  @Prop({ default: false, type: Boolean }) defaultAllImages: boolean;

  @Prop({ default: () => [] })
  combinedImageLibrary: (UserImage|MemberImage)[];

  // @Prop({ default: false }) exposeInput: boolean;
  //
  // @Prop({ default: false }) hidingInput: boolean;

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('recommendedAds/getMemberImages') memberImageLibrary: MemberImage[];

  @Getter('layout/isTouch') isTouch: boolean;

  get isMobile() {
    return this.$store.getters['layout/isMobile'];
  }

  get currentLibraryImages() {
    return this.memberImageLibrary.slice(0);
  }

  get releaseGenre() {
    return getUGC('releaseGenre');
  }

  get genreForDisplay() {
    return genreForDisplay(this.releaseGenre);
  }

  modalLoadingIndicator: boolean = false;

  exposeUpload: boolean = false;

  hidingUpload: boolean = false;

  scrollDist: number = 0;

  scrollTrigger: number = 0;

  eventListenerInstantiated: boolean = false;

  modalBodyElement: HTMLElement | null = null;

  blurBottom: boolean = false;

  blurTop: boolean = false;

  filterSelection: string = 'Interactive ad background images';

  filteringOptions: string[] = [
    'All images',
    'Artist performing images',
    'Fan focused images',
    `${this.genreForDisplay} images`,
    'Local Interest images',
    'Interactive ad background images',
  ];

  filteringMap: FilterMapValidation[] = [
    {
      key: 'All images',
      validation: true,
    },
    {
      key: 'Artist performing images',
      validation: 'Performing',
    },
    {
      key: 'Fan focused images',
      validation: 'Fan',
    },
    {
      key: `${this.genreForDisplay} images`,
      validation: 'Genre',
    },
    {
      key: 'Local Interest images',
      validation: 'Geo',
    },
    {
      key: 'Interactive ad background images',
      validation: 'Wallpaper',
    },
  ];

  sortImagesBySelected(e1: MemberImage, e2: MemberImage): number {
    if (this.selectedIndex(e1.id) === -1) {
      // e1 is not selected
      if (this.selectedIndex(e2.id) > -1) {
        // but e2 is selected
        return 1;
      }
      return parseInt(e1.id, 10) - parseInt(e2.id, 10);
    }
    if (this.selectedIndex(e1.id) > -1) {
      // e1 is selected
      if (this.selectedIndex(e2.id) > -1) {
        // and e2 is selected
        return this.selectedIndex(e1.id) - this.selectedIndex(e2.id);
      }
      return -1;
    }
    return 0;
  }

  libraryImagesForSelection(filterSelection: string) {
    const mapIndex = this.filteringMap.findIndex((filterMap) => filterMap.key === filterSelection);
    const mapValidation = this.filteringMap[mapIndex].validation;

    return this.currentLibraryImages.filter((memberImage) => mapValidation === true
      || memberImage.theme === mapValidation
      || (typeof mapValidation === 'function'
        && mapValidation(memberImage.id) === true));
  }

  get filteredLibraryImages() {
    return this.libraryImagesForSelection(this.filterSelection);
  }

  get filteringOptionsWithImages(): string[] {
    return this.filteringOptions.filter((option) => this.libraryImagesForSelection(option).length > 0);
  }

  get hasFilterOptions() {
    return this.filteringOptionsWithImages.length > 0;
  }

  @Watch('filterSelection')
  watchFilterSelection() {
    this.currentLibraryImages.sort(this.sortImagesBySelected);
    this.scrollToTop();
  }

  @Watch('filteringOptionsWithImages')
  watchFilteringOptions(newOptions: string[]) {
    const selIndex = newOptions.findIndex((i) => i === this.filterSelection);
    if (selIndex === -1) {
      this.filterSelection = 'All images';
    }
  }

  get dimImages() {
    if (this.limit === -1) {
      return false;
    }
    return this.validSelectedImageIds.length >= this.limit;
  }

  get validSelectedImageIds() {
    const currentLibraryImagesIds = this.currentLibraryImages.map((li) => li.id);
    return this.selectedImageIds.filter((si) => currentLibraryImagesIds.includes(si));
  }

  mounted() {
    this.modalBodyElement = document.getElementById('member-images');
    this.currentLibraryImages.sort(this.sortImagesBySelected);
    this.filterSelection = 'All images';

    if (this.modalBodyElement && !this.eventListenerInstantiated) {
      this.modalBodyElement.addEventListener('scroll', this.handleScroll, { passive: true });
      this.eventListenerInstantiated = true;
    }
  }

  blurWhenSelected(elementId: string) {
    const elem = document.getElementById(elementId) || null;
    if (elem) {
      elem.blur();
    }
  }

  scrollToTop() {
    this.modalBodyElement = document.getElementById('member-images');

    if (this.modalBodyElement) {
      this.modalBodyElement.scrollTo(0, 0);
    }
  }

  handleScroll() {
    if (this.modalBodyElement) {
      const dist = this.modalBodyElement!.scrollTop;
      const overscrolled = dist + this.modalBodyElement!.offsetHeight + 100 >= this.modalBodyElement!.scrollHeight;
      this.blurBottom = dist < this.modalBodyElement.scrollHeight - this.modalBodyElement.offsetHeight - 55;
      this.blurTop = dist > 48;

      if (dist === 0 || (this.isTouch && dist < 55)) {
        // at the top of the page, so ensure no fixed tabs (and no animation)
        if (this.exposeUpload) {
          this.exposeUpload = false;
        }
        this.scrollTrigger = 0;
      } else if (dist < this.scrollDist && !overscrolled) {
        // scrolling up, expose fixed tabs if far enough down the page and past buffer pixels
        // this.exposeUpload = this.exposeUpload || (dist > 150 && dist < this.scrollTrigger);
        this.exposeUpload = this.exposeUpload || (dist > 55 && dist < this.scrollTrigger);
        this.hidingUpload = false;
        if (this.exposeUpload) {
          this.scrollTrigger = dist;
        }
      } else if (this.exposeUpload && dist > this.scrollTrigger) {
        // scrolling down and past buffer pixels, hide
        this.hidingUpload = true;
      } else {
        this.scrollTrigger = Math.max(this.scrollTrigger, this.scrollDist);
      }
      this.scrollDist = dist;
    }
  }

  isSelected(imageId: string): boolean {
    return this.selectedIndex(imageId) !== -1;
  }

  selectedIndex(imageId: string): number {
    const combinedImageIds = this.combinedImageLibrary.map((li) => {
      if ('theme' in li) {
        return li.id;
      }
      return `ui-${li.id}`;
    });

    const index = combinedImageIds.findIndex((i) => i === imageId);
    if (index > -1) {
      return index;
    }
    return -1;
  }

  getThumbnailUrl(image: MemberImage) {
    return imgixFunctions.getMemberImageThumbnailUrl(image.url);
  }

  toggleImageTempSelected(imageId: string) {
    if (this.validSelectedImageIds.indexOf(imageId) === -1) {
      if (this.dimImages) {
        this.$emit('imageLimitReached');
      } else {
        this.$emit('addImage', imageId);
      }
    } else {
      this.$emit('removeImage', imageId);
    }
  }
}
