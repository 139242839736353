

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AddImagesButton extends Vue {
@Prop({ default: 'uploadImageButton' })
buttonClass: string;

@Prop({ default: () => ['fas', 'image'] })
icon: string[];

@Prop({ default: 'Add Images' })
copy: string;

@Prop({ default: 'fa-1x' })
sizeClass: string;
}
