var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"memberImages",staticClass:"member-images flex fill-last-row flex-wrap blur-top blur-bottom",class:{
    'btactive': _vm.blurTop,
    'bbactive': _vm.blurBottom,
  },attrs:{"id":"member-images"}},[(_vm.hasFilterOptions)?_c('div',{staticClass:"filter-dropdown",class:{
      'expose-upload': _vm.exposeUpload,
      'hiding-upload': _vm.hidingUpload,
    }},[_c('div',{staticClass:"filter-container"},[_c('SelectDropdown',{staticClass:"form-field__control form-field__control__light-background double-column",attrs:{"element-id":"filter","options":_vm.filteringOptionsWithImages,"label":"Filter images","autofocus":false,"select-classes":{
          'form-field__input form-field__input__light form-field__input__light-background': true,
        },"container-class":"select select__light-background","label-classes":"form-field__label form-field__label__light","error-notification-classes":"error-notification form-error error-notification__light"},on:{"change":function($event){return _vm.blurWhenSelected('filter')}},model:{value:(_vm.filterSelection),callback:function ($$v) {_vm.filterSelection=$$v},expression:"filterSelection"}})],1)]):_vm._e(),_vm._l((_vm.filteredLibraryImages),function(image){return _c('div',{key:("member_image_" + (image.id)),staticClass:"library-image"},[_c('b-img-lazy',{staticClass:"image-thumbnail d-none d-md-block",class:{ selected: _vm.isSelected(image.id) },attrs:{"src":_vm.getThumbnailUrl(image),"blank-color":"rgba(0, 0, 0, 0.1)"}}),_c('b-img-lazy',{staticClass:"image-thumbnail d-md-none",class:{ selected: _vm.isSelected(image.id) },attrs:{"src":_vm.getThumbnailUrl(image),"blank-color":"rgba(0, 0, 0, 0.1)"}}),_c('div',{staticClass:"image-shadow",class:{
        'dim-images': _vm.dimImages,
        'selected': _vm.isSelected(image.id),
      },on:{"click":function($event){return _vm.toggleImageTempSelected(image.id)}}}),(_vm.isSelected(image.id))?_c('div',{staticClass:"selected-image",class:{ 'icon-only': !_vm.showSelectedCount },on:{"click":function($event){return _vm.toggleImageTempSelected(image.id)}}},[(_vm.showSelectedCount)?_c('strong',[_vm._v(" "+_vm._s(_vm.selectedIndex(image.id) + 1)+" ")]):_c('strong',[_c('fa-icon',{staticClass:"icon",attrs:{"icon":['fas', 'check']}})],1)]):_vm._e()],1)}),(!_vm.hasFilterOptions)?_c('div',{staticClass:"no-available-images"},[_c('p',[_vm._v(" Unfortunately, there are no Zire images for your given release. ")])]):_vm._e(),(_vm.modalLoadingIndicator)?_c('div',{staticClass:"loading-indicator-container"},[_c('fa-icon',{staticClass:"fa-spin fa-2x",staticStyle:{"color":"#7ED321"},attrs:{"icon":['far', 'circle-notch']}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }