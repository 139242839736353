import { render, staticRenderFns } from "./ErrorModal.vue?vue&type=template&id=3442a1ae&scoped=true&"
import script from "./ErrorModal.vue?vue&type=script&lang=ts&"
export * from "./ErrorModal.vue?vue&type=script&lang=ts&"
import style0 from "./ErrorModal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ErrorModal.vue?vue&type=style&index=1&id=3442a1ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3442a1ae",
  null
  
)

export default component.exports