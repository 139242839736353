
















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import infiniteScroll from 'vue-infinite-scroll';

import { MemberImage, UserImage } from '@/shared/gen/messages.pisa';
import * as imgixFunctions from '@/shared/lib/imgixFunctions';
import { InvalidImageDimensionsError } from '@/shared/store/imageLibrary';

import AddImagesButton from '@/shared/components/builder/AddImagesButton.vue';
import OpenImageLibraryButton from '@/shared/components/builder/OpenImageLibraryButton.vue';
import ProgressBarComponent from '@/shared/components/common/ProgressBarComponent.vue';

@Component({
  components: {
    AddImagesButton,
    OpenImageLibraryButton,
    ProgressBarComponent,
  },
  directives: {
    infiniteScroll,
  },
})
export default class UploadedImages extends Vue {
  $refs!: {
    uploadedImages: HTMLElement,
  }

  progressBarColor: string = '#552EFF';

  @Prop({ default: () => [] }) selectedImageIds: string[];

  @Prop({ default: () => [] }) disabledUserImageIds: string[];

  @Prop({ default: -1 }) limit: number;

  @Prop({ default: false, type: Boolean }) showSelectedCount: boolean;

  @Prop({ default: true, type: Boolean }) autoSelectUploaded: boolean;

  @Prop({ default: () => [] })
  combinedImageLibrary: (UserImage|MemberImage)[];

  @Getter('profile/isLoggedInWithEmail') isLoggedInWithEmail: boolean;

  @Getter('layout/isTouch') isTouch: boolean;

  @Getter('imageLibrary/getLibrary') imageLibrary: UserImage[];

  @Getter('imageLibrary/getTotalImages') totalImages: number;

  @Getter('imageLibrary/imageUploadsInProgress') imageUploadsInProgress: number;

  @Getter('imageLibrary/numberPerPage') userNumberPerPage: number;

  @Getter('upload/activeUploads') currentUploads: string[];

  @Getter('upload/uploadPercentage') uploadPercentage: number;

  get isMobile() {
    return this.$store.getters['layout/isMobile'];
  }

  get currentLibraryImages() {
    return this.imageLibrary.slice(0);
  }

  get moreImagesToLoad() {
    return (this.imageLibrary.length < this.totalImages);
  }

  isDisabled(imageId: string) {
    return this.disabledUserImageIds.indexOf(imageId) > -1;
  }

  modalLoadingIndicator: boolean = false;

  filesToUpload: any[] = [];

  exposeUpload: boolean = false;

  hidingUpload: boolean = false;

  forbidTransition: boolean = false;

  scrollDist: number = 0;

  scrollTrigger: number = 0;

  eventListenerInstantiated: boolean = false;

  modalBodyElement: HTMLElement | null = null;

  blurBottom: boolean = true;

  blurTop: boolean = false;

  get dimImages() {
    if (this.limit === -1) {
      return false;
    }
    return this.selectedImageIds.length >= this.limit;
  }

  mounted() {
    this.modalBodyElement = document.getElementById('uploaded-images');

    if (this.modalBodyElement && !this.eventListenerInstantiated) {
      this.modalBodyElement.addEventListener('scroll', this.handleScroll, { passive: true });
      this.eventListenerInstantiated = true;
    }

    if (this.imageLibrary.length === 0) {
      this.$store.dispatch('imageLibrary/loadLibrary').then(() => {});
    }
  }

  login() {
    this.$root.$emit('bv::show::modal', 'login-modal');
  }

  handleScroll() {
    if (this.modalBodyElement) {
      const dist = this.modalBodyElement!.scrollTop;
      const overscrolled = dist + this.modalBodyElement!.offsetHeight + 100 >= this.modalBodyElement!.scrollHeight;
      this.blurBottom = dist < this.modalBodyElement.scrollHeight - this.modalBodyElement.offsetHeight - 48;
      this.blurTop = dist > 48;

      if (dist === 0 || (this.isTouch && dist < 45)) {
        // at the top of the page, so ensure no fixed tabs (and no animation)
        if (this.exposeUpload) {
          this.forbidTransition = true;
          this.exposeUpload = false;
          setTimeout(() => {
            this.forbidTransition = false;
          }, 250);
        }
        this.scrollTrigger = 0;
      } else if (dist < this.scrollDist && !overscrolled) {
        // scrolling up, expose fixed tabs if far enough down the page and past buffer pixels
        this.exposeUpload = this.exposeUpload || (dist > 150 && dist < this.scrollTrigger);
        this.hidingUpload = false;
        if (this.exposeUpload) {
          this.scrollTrigger = dist;
        }
      } else if (this.exposeUpload && dist > this.scrollTrigger) {
        // scrolling down and past buffer pixels, hide
        this.hidingUpload = true;
      } else {
        this.scrollTrigger = Math.max(this.scrollTrigger, this.scrollDist);
      }
      this.scrollDist = dist;
    }
  }

  uploadFiles() {
    if (this.filesToUpload.length > 0) {
      this.$store.commit('imageLibrary/clearUploadErrors');
      this.filesToUpload.forEach((file) => {
        const image = new Image();
        image.onload = () => {
          const { width, height } = image;
          if (width < 600 || height < 500 || ((width / height) > 2.5)) {
            this.$store.commit('imageLibrary/addUploadError', { name: file.name, error: InvalidImageDimensionsError });
            this.$emit('showErrorModal');
          } else {
            this.$store.dispatch('imageLibrary/uploadToLibrary', file).then((uploadedImage) => {
              if (this.autoSelectUploaded) {
                this.$emit('addImage', uploadedImage.id);
              }
            }).catch(() => {
              this.$emit('showErrorModal');
            });
          }
        };
        image.src = window.URL.createObjectURL(file);
      });
      this.filesToUpload = [];
    }
  }

  passClickToFileInput(evt: Event) {
    evt.preventDefault();
    document.getElementById('imageFileUploader')!.click();
  }

  isSelected(imageId: string): boolean {
    return this.selectedIndex(imageId) !== -1;
  }

  selectedIndex(imageId: string): number {
    const combinedImageIds = this.combinedImageLibrary.map((li) => {
      if ('theme' in li) {
        return `mi-${li.id}`;
      }
      return li.id;
    });

    return combinedImageIds.findIndex((i) => i === imageId);
  }

  getThumbnailUrl(image: UserImage) {
    return imgixFunctions.getImageThumbnailUrl(image.url);
  }

  toggleImageTempSelected(imageId: string) {
    if (this.isDisabled(imageId)) {
      return false;
    }
    if (this.selectedImageIds.indexOf(imageId) === -1) {
      if (this.dimImages) {
        this.$emit('imageLimitReached');
      } else {
        this.$emit('addImage', imageId);
      }
    } else {
      this.$emit('removeImage', imageId);
    }
    return true;
  }

  loadNextSetImages() {
    if (this.moreImagesToLoad) {
      this.modalLoadingIndicator = true;
      this.$store.dispatch('imageLibrary/loadNextPage').then(() => {
        this.modalLoadingIndicator = false;
      });
    }
  }
}
