




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageOverlay from '@/shared/components/builder/ImageOverlay.vue';

@Component({
  components: {
    ImageOverlay,
  },
})
export default class OpenImageLibraryButton extends Vue {
  @Prop({ default: '' })
  iconClass: string;

  @Prop({ default: 'fa-1_25x' })
  sizeClass: string;

  @Prop({ default: () => ['fas', 'image'] })
  icon: string[];

  @Prop({ default: '' })
  hovertext: string;

  @Prop({ default: 0 })
  numToDisplay: number;

  @Prop({ default: false, type: Boolean })
  showHover: boolean;

  @Prop({ default: '' })
  imageOverlayClass: string;

  @Prop({ default: false, type: Boolean })
  noOverlay: boolean;
}
