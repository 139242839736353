
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { BModal } from 'bootstrap-vue';

@Component
export default class ErrorModal extends Vue {
  $refs!: {
    modal: BModal,
  };

  @Prop({ required: true }) modalId: string;

  @Prop({ default: '' }) size: string;

  @Prop({ default: '' }) title: string;

  @Prop({ default: false, type: Boolean }) noCloseOnBackdrop: boolean;

  @Prop({ default: false, type: Boolean }) noCloseOnEsc: boolean;

  @Getter('layout/isMobile') isMobile: boolean;

  show() {
    this.$refs.modal.show();
  }

  hide(trigger?: string) {
    this.$refs.modal.hide(trigger);
  }
}
