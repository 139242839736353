



















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})

export default class SelectDropdown extends Vue {
  $refs!: {
    [key: string]: any,
  };

  @Prop({ default: '' })
  value: string;

  @Prop({ default: '' })
  elementId: string;

  @Prop({ default: '' })
  elementRef: string;

  @Prop({ default: () => [] })
  options: any[];

  @Prop({ default: 0 })
  tabIndex: number;

  @Prop({ default: false })
  autofocus: boolean;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: false })
  showError: boolean;

  @Prop({ default: '' })
  errorText: string;

  @Prop({ default: 'select' })
  containerClass: string;

  @Prop({ default: 'form-field__input' })
  selectClasses: string;

  @Prop({ default: 'form-field__label' })
  labelClasses: string;

  @Prop({ default: 'error-notification form-error' })
  errorNotificationClasses: string;

  @Prop({ default: false })
  focus: boolean;

  get userInput() {
    return this.value;
  }

  set userInput(newValue) {
    this.$emit('input', newValue);
  }

  mounted() {
    if (this.focus && this.$refs[this.elementRef]) {
      this.$nextTick(() => {
        this.$refs[this.elementRef].focus();
      });
    }
  }

  updated() {
    if (this.focus && this.$refs[this.elementRef]) {
      this.$nextTick(() => {
        this.$refs[this.elementRef].focus();
      });
    }
  }
}
